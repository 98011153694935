<template>
  <div class="wrap">
    <pageHead></pageHead>
    <div class="warp AppPageWarp" v-for="(item,index) in AppPage" :key="index">

        <div class="AppPageBox box reveal-top">
          <div class="AppPageBoxLeft">
            <el-image :style="{width:'100%',}" :src="item.image" fit="contain"></el-image>
          </div>
          <div class="AppPageBoxCenter">
            <div class="AppPageBoxCenterTop">
              <el-image v-if="item.type==1" style="width: 100%" :src="item.title" fit="contain"></el-image>
              <h1 v-else>{{item.title}}</h1>
            </div>
            <div class="AppPageBoxCenterBottom">
                <div v-if="item.type==1" class="AppPageBoxCenterBottomCodeBox">
                  <el-image style="width:50%;height: auto;" :src="allInfo.code" fit="contain"></el-image>
                  <div class="AppPageBoxCenterBottomCodeCenter">
                    <el-image @click="toDownload('android')" v-if="item.type==1" style="width: 100%;margin-bottom: 15px;cursor: pointer;" :src="allInfo.Android" fit="contain"></el-image>
                    <el-image @click="toDownload('ios')" v-if="item.type==1" style="width: 100%;cursor: pointer;" :src="allInfo.iOS" fit="contain"></el-image>
                  </div>
                </div>
                <div class="AppPageBoxCenterBottomCenter" v-html="item.describe" v-else>
                </div>
            </div>
          </div>
        </div>

    </div>
    <pageFoot></pageFoot>
  </div>
</template>

<script>
import pageHead from "@/views/assembly/pageHead/pageHead";
import pageFoot from "@/views/assembly/pageFoot/pageFoot";
import scrollReveal from 'scrollreveal';
export default {
  name: "AppPage",
  data(){
    return{
      AppPage:this.$store.state.pageInfo.AppPage,
      allInfo:this.$store.state.pageInfo.allPage,
      num:-1,
      top:0,
      scrollReveal: scrollReveal()
    }
  },
  components:{
    pageHead,
    pageFoot
  },
  mounted() {
    console.log(this.AppPage)
    this.scrollReveal.reveal('.reveal-top', {
      // 动画的时长
      duration: 500,
      // 延迟时间
      delay: 200,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 距离滚动条顶部的距离
      viewFactor: 0.2,
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    window.addEventListener('scroll', this.getScroll);
    this.num=0
  },
  destroyed(){
    window.removeEventListener('scroll', this.getScroll);
  },
  methods:{
    toDownload(e){
      this.$store.dispatch('toDownload',e)
    },
    getScroll(){
      if ((!!document.documentElement.scrollTop &&document.documentElement.scrollTop)-this.top >= 700){
        this.top=!!document.documentElement.scrollTop &&document.documentElement.scrollTop
        this.num=Number(( this.top/700).toFixed(0))
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .AppPageWarp{
    background: #F7F7F7;
      width: 100%;
      min-height: 800px;
      .AppPageBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: wrap;
        height: 100%;
        padding: 12% 0;
      }
      &:nth-child(1){
        .AppPageBox{
          padding: 0 40px;
        }
      }
      &:nth-child(2n+1){
        background: #ffffff;
        .AppPageBox{
          flex-direction: row-reverse;
        }
      }
    .AppPageBoxLeft{
      width: 50%;
      padding:0 5%;
      box-sizing: border-box;
    }
    .AppPageBoxCenter{
      width: 50%;
      padding:0 10%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .AppPageBoxCenterBottom{
      margin-top: 5%;
    }
    .AppPageBoxCenterBottomCodeBox{
      display: flex;
      align-items: center;
      //padding-top: 28px;
    }
    .AppPageBoxCenterBottomCodeCenter{
      display: flex;
      flex-direction: column;
      width: 50%;
      box-sizing: border-box;
      padding: 5%;
    }
    .AppPageBoxCenterBottomCenter{
      width: 100%;
      height: 100%;
      padding-bottom: 200px;
    }
  }
  @keyframes example {
    from {opacity: 0}
    to {opacity: 1}
  }
</style>